import React, {useEffect} from "react";
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from './initFirebase';
import {set,push} from "firebase/database";

const auth = firebase.auth();

function SetStore() {
  const [user, loading] = useAuthState(auth);

  /*useEffect(async () => { // Previous (working) code fixed (below) after warning.
    if (loading) return;
    if (user) await saveData()
    else alert('A user should be logged in to successfully use this route.')
  }, [user, loading]);*/

  useEffect(() => {
    async function processDataStore() {
      if (loading) return;
      if (user) await saveData()
      else alert('A user should be logged in to successfully use this route.')
    }
    processDataStore()
  }, [user, loading]);

  const saveData = async() => {
    const registerWebSite = (name,url,lng) => {
      const dbWebSiteRef = firebase.database().ref("/WorldLife/SideMedia/WebSites/")
      const newDocRef=push(dbWebSiteRef)
      set(newDocRef, {
        name: name,
        url: url,
        lang: lng
      }).then(() => {
        //alert('Site registered successfully.')
      }).catch((error)=> {
        alert('Error in registerWebSite :', error.message)
      })
    } /* End of registerWebSite */

    const registerNewsChannel = (name,url,lng) => {
      const dbNwsChanSiteRef = firebase.database().ref("/WorldLife/SideMedia/NewsChannels/")
      const newDocRef=push(dbNwsChanSiteRef)
      set(newDocRef, {
        name: name,
        url: url,
        lang: lng
      }).then(() => {
        //alert('Channel registered successfully.')
      }).catch((error)=> {
        alert('Error in registerNewsChannel :', error.message)
      })
    } /* End of registerNewsChannel */

    const registerAllWebSites = () => {
      registerWebSite('Politis','https://www.politis.fr/','FR')
      registerWebSite('blast','https://www.blast-info.fr/','FR')
      registerWebSite('MEDIAPART','https://www.mediapart.fr/','FR')
      registerWebSite('basta!','https://basta.media/','FR')
      registerWebSite('vert','https://vert.eco/','FR')
      registerWebSite('BonPote','https://bonpote.com/','FR')
      registerWebSite('Reporterre','https://reporterre.net/','FR')
      registerWebSite('Le 1 hebdo','https://le1hebdo.fr/','FR')
      registerWebSite('Socialter','https://www.socialter.fr/','FR')
      registerWebSite('Radio Courtoisie','https://www.radiocourtoisie.fr/','FR')
      registerWebSite('Redacted','https://redacted.inc/','EN')
      registerWebSite('Democracy Now!','https://www.democracynow.org/','EN')
    } /* End of registerAllWebSites */

    const registerAllChannels = () => {
      registerNewsChannel('Afrique Média',
      'https://www.youtube.com/@afriquemediatv','FR')
      registerNewsChannel('Putsch Media',
      'https://www.youtube.com/@PutschLive','FR')
      registerNewsChannel('Les Incorrectibles',
      'https://www.youtube.com/@LesIncorrectibles','FR')
      registerNewsChannel('Union Populaire Républicaine',
      'https://www.youtube.com/@UprFr','FR')
      registerNewsChannel('Forbes Breaking News',
      'https://www.youtube.com/@ForbesBreakingNews','EN')
      registerNewsChannel('Sud Radio',
      'https://www.youtube.com/@SudRadioOfficiel','FR')
      registerNewsChannel('Le Média',
      'https://www.youtube.com/@LeMediaOfficiel','FR')
      registerNewsChannel('Redacted',
      'https://www.youtube.com/@RedactedNews','EN')
      registerNewsChannel('Ligne Droite • La matinale de Radio Courtoisie',
      'https://www.youtube.com/@LigneDroiteMatinale','FR')
      registerNewsChannel('Révolution Permanente',
      'https://www.youtube.com/@RevolutionPermanente','FR')
      registerNewsChannel('Thinkerview',
      'https://www.youtube.com/@thinkerview','FR')
      registerNewsChannel('Epoch Times France',
      'https://www.youtube.com/@EpochTimesFR','FR')
      registerNewsChannel('Osons Causer',
      'https://www.youtube.com/@Osonscauser','FR')
      registerNewsChannel('Éthique et tac',
      'https://www.youtube.com/@ethiqueettac','FR')
      registerNewsChannel('ÉLUCID',
      'https://www.youtube.com/@ElucidMedia','FR')
      registerNewsChannel('First Liberty Live',
      'https://www.youtube.com/@FirstLibertyLive','EN')
      registerNewsChannel('Le Précepteur',
      'https://www.youtube.com/@Le_Precepteur','FR')
      registerNewsChannel('Les Visionnaires',
      'https://www.youtube.com/@LesVisionnairesYT','FR')
      registerNewsChannel('Best Documentary',
      'https://www.youtube.com/@BestDoc','EN')
      registerNewsChannel('Innovation Tour',
      'https://www.youtube.com/@innovationtour','EN')
      registerNewsChannel('UnHerd',
      'https://www.youtube.com/@UnHerd','EN')
      registerNewsChannel('Max Milo Éditions',
      'https://www.youtube.com/@MaxMiloEditionsYT','FR')
      registerNewsChannel('Neutrality Studies',
      'https://www.youtube.com/@neutralitystudies','EN')
      registerNewsChannel('Geopolitical Economy Report',
      'https://www.youtube.com/@GeopoliticalEconomyReport','EN')
      registerNewsChannel('Anti | thèse',
      'https://www.youtube.com/@antitheseinfo','FR')
      registerNewsChannel('Publications Agora',
      'https://www.youtube.com/@PublicationsagoraFrance','FR')
    } /* End of registerAllChannels */

    // Websites registration.
    if (false) registerAllWebSites()
    // News Channels registration.
    if (false) registerAllChannels()
  }
  
  return(
    <div>SetStore</div>
  )
}


export default SetStore;
