import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { /*auth, signInWithEmailAndPassword,*/ signInWithGoogle } from "./firebase";
import firebase from './initFirebase';
import { useAuthState } from "react-firebase-hooks/auth";
import "./Login.css";

var utl = require('./Utils.js');
const auth = firebase.auth();


const signInWithEmailAndPassword = async (email, password) => {
  try {
    await auth.signInWithEmailAndPassword(email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};


const missPaswdStr = () => {
  let lng = utl.langPrefer()
  switch (lng) {
    case 'F':
  	return 'En cas de mot de passe oublié ou inconnu';
    case 'J':
  	return 'パスワードを忘れたまたは知らない場合';
    default:
  	return 'In case of Password Forgotten or Unknown';
  }
};


function Login(props) {
  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");
  const [user,loading] = useAuthState(auth);
  const history = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }

    if (user) {
      //history("/docinp",{replace:true});
      if (props.origin === 'mng') history("/docmng",{replace:true});
      else if (props.origin === 'mng2') history("/docmng2",{replace:true});
      else history("/docinp",{replace:true});
    }
  }, [user,loading,props.origin,history]);
  return (
    <div className="login">
      <div className="login__container">
        <input
          type="text"
          className="login__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <input
          type="password"
          className="login__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button
          className="login__btn"
          onClick={() => signInWithEmailAndPassword(email, password)}
        >
          Login
        </button>
        {/* {false &&
        <button className="login__btn login__google" onClick={signInWithGoogle}>
          Login with Google
        </button>} */}
        <div>
          <Link to="/reset">{missPaswdStr()}</Link>
        </div>
        {false &&
        <div>
          Don't have an account? <Link to="/register">Register</Link> now.
        </div>}
      </div>
    </div>
  );
}

export default Login;
