import React from "react";
import firebase from './initFirebase.js';
import './NewsChannels.css';
// import DocInput from "./DocInput.js";


function IntroEnglish(props) {
  return (
    <React.Fragment>
      <div className="dilmHd">
        Alternative News Channels
      </div>
      <div className={props.styl}>
        Though we now live in a society overwhelmed with information, it has also become more difficult to find the authentic truth about matters we care about. While it is technically easier to access news, information has, like many other things, become like a commodity that one can purchase or get for free. And companies providing it see information as a means of profit-making where financial motives often take precedence over the information itself, its quality and reliability. Besides, information is a particular product which can influence the way we think, and as such, there is a significant tendency for political powers and various media owners to present it in ways fitting their interests, opinions and intentions. The collection of channels available here, is meant as a few more resources for the visitor to choose from.<br/>
        The goal is not to tell you which channel to choose or what to think. It is to provide some options to find resources outside the classical main narrative concerning world events.<br/>
        The collection currently contains <span>{props.nb}</span> channels. They are included here because they have been deemed valuable for our purpose. One can discover more by conducting adequate research. Click on your desired channel to access it.
      </div>
    </React.Fragment>
  )
} /* End of IntroEnglish */


function IntroFrench(props) {
  return (
    <React.Fragment>
      <div className="dilmHd">
        Chaînes d'Information Alternatives.
      </div>
      <div className={props.styl}>
        Bien que nous vivions dans une société submergée d'informations, il devient de plus en plus difficile de faire la part entre le vrai et le faux. Faire le tri entre ce qui est important et ce qui n'est que propagande ou pollution médiatique requiert esprit critique et concentration. Alors qu'accéder à une information particulière est maintenant très facile, le contenu informationnel est devenu un bien de consommation parmi tant d'autres. Et en tant que tel c'est aussi un moyen de faire du profit, qui de part son potentiel d'influence sur la manière de penser de l'utilisateur attire la convoitise des pouvoirs politiques et de ceux qui cherche à contrôler les opinions.<br/>
        L'objectif n'est pas ici de dire ce qu'il faut choisir ou penser; il est seulement d'apporter des ressources supplémentaires dans le choix de chaînes d'information alternatives, permettant d'obtenir des points de vue hors des clichés bien-pensant promus par les courants à la mode. Il est possible d'en découvrir plus en faisant votre recherche personnelle.<br/>
        La série contient actuellement <span>{props.nb}</span> chaînes qui semblent intéressantes pour le but recherché. Il suffit de cliquer sur celle à laquelle vous voulez accéder.
      </div>
    </React.Fragment>
  )
} /* End of IntroFrench */


function Introduction(props) {
  switch (props.usrPrfLng) {
    case 'E':
      return IntroEnglish(props)
    case 'F':
      return IntroFrench(props)
    default:
      return "UNKNOWN LANGUAGE:"+props.usrPrfLng+'!'
  }
} /* End of Introduction */


function show(lng,usrPrfLng) {
  let lngKwd
  switch(usrPrfLng) {
    case 'E':
      lngKwd = 'Language:'
      if (lng==='EN') return lngKwd+'English'
      if (lng==='FR') return lngKwd+'French'
      break;
    case 'F':
      lngKwd = 'Langue: '
      if (lng==='EN') return lngKwd+'anglais'
      if (lng==='FR') return lngKwd+'français'
      break;
    default:
      return "UNKNOWN LANGUAGE:"+usrPrfLng+'!'
  }
} /* End of show */


class NewsChannels extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // ['dataRcdsArray']: [],
      dataRcdsArray: [],
      // showEditBox: false,
      usrPrefLng: props.upl
    };

    // if (props.mng === 'T') {
    //   this.mngFlag = true
    //   this.owner = props.ownr
    //   this.uid = ''
    //   this.ediTitle = ''
    //   this.url = ''
    //   this.author = ''
    //   this.lang = ''
    // }
   }

  componentDidMount() {
    this.getUserData();
    // this.getUserData0();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      console.log("Would do : -writeUserData-")
      //this.writeUserData();
    }
  }

  getUserData = () => {
    let ref = firebase.database().ref("/WorldLife/SideMedia/NewsChannels");
    ref.on("value", snapshot => {
      const syncData = snapshot.val();

      this.setState({dataRcdsArray: syncData})
    });
  };


  render() {
    let dmIntrStyl, dcBnStyl, dcAthStyl

    if (window.matchMedia("(max-width: 375px)").matches) {
      dmIntrStyl = 'dilmIntro dmIntrFnSz2'
      dcBnStyl = 'docBtnAC dcBnFnSz2'
      dcAthStyl = 'docAuth dcAtFnSz2'
      // console.log('matchMedia: max-width=375px YES')
    } else if (window.matchMedia("(max-width: 768px)").matches) {
      dmIntrStyl = 'dilmIntro dmIntrFnSz1'
      dcBnStyl = 'docBtnAC dcBnFnSz1'
      dcAthStyl = 'docAuth dcAtFnSz1'
    } else {
      dcBnStyl = 'docBtnAC dcBnFnSz0'
      dcAthStyl = 'docAuth dcAtFnSz0'
      dmIntrStyl = 'dilmIntro dmIntrFnSz0'
      // console.log('matchMedia: max-width=375px NO')
    }

    var clctnBufr = [];
    for (var i in this.state.dataRcdsArray)
      clctnBufr.push([i,this.state.dataRcdsArray[i]]);

    const rcdsNbr = Object.keys(this.state.dataRcdsArray).length;

    return (
      <React.Fragment>
        <div className="container">
          <Introduction styl={dmIntrStyl} nb={rcdsNbr}
            usrPrfLng={this.state.usrPrefLng} />
          <div className='docsLstAC'>
            {clctnBufr.map((item,index) => (
              <div key={item.uid}>
                <div className="docCardAC">
                  <button type="submit" className="btnAC"
                          onClick={() => window.open(item[1].url, "_blank")}>
                    <label className={dcBnStyl}>{item[1].name}</label>
                  </button>
                  <div className="authrBtnBlk">
                    <label className='langLabl'>{show(item[1].lang,this.state.usrPrefLng)}</label>
                    <label className={dcAthStyl}>{item.name}</label>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default NewsChannels;
