import React from "react";

const makeQR_URL = (url) => {
  let resURL = 'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data='
  resURL += url
  return resURL
}

function QR() {
  const theURL=makeQR_URL("https://world2024.space/")
  return (
    <React.Fragment>
      <img src={theURL} alt="Logo" />
    </React.Fragment>
  )
}

export default QR;