import React from "react";
import QR from "./QR";
import './SharePage.css';


const siteURL = () => {return "https://world2024.space/"}


const shareXplain = (lng) => {
  switch (lng) {
    case 'E': 
    return 'You can easily share this website by giving its address';
    case 'F': 
    return 'Vous pouvez facilement partager ce site en donnant son adresse';
    default:
      return "UNKNOWN LANGUAGE:"+lng+'!'
  }
}


const qrXplain = (lng) => {
  switch (lng) {
    case 'E': 
    return 'You can also use the QR code below for an easy scan';
    case 'F': 
    return 'Vous pouvez aussi aisément scanner le code QR ci-dessous';
    default:
      return "UNKNOWN LANGUAGE:"+lng+'!'
  }
}


const cpyBtnTxt = (lng) => {
  switch (lng) {
    case 'E': 
    return 'Copy the URL';
    case 'F': 
    return "Copier l'URL";
    default:
      return "UNKNOWN LANGUAGE:"+lng+'!'
  }
}


function cpyURLToClpBd() {
  navigator.clipboard.writeText(siteURL()).then(() => {
  },() => {});
} /* End of cpyURLToClpBd */


function SharePage(props) {
  return (
    <React.Fragment>
      <div id='ShareBlock'>
        {shareXplain(props.upl)}:
        <div id='urlBox'>
          {siteURL()}
        </div>
 				<div id='urlBtn'>
					<button onClick={() => cpyURLToClpBd()}
									id="cpyBtn">
						<div id='cpyTxt'>{cpyBtnTxt(props.upl)}</div>
					</button>
				</div>
        {qrXplain(props.upl)}.
        <div id='QRBox'>
          <QR/>
        </div>
      </div>
    </React.Fragment>
  )
} /* End of SharePage */

export default SharePage;
