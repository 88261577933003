import React, {useEffect} from "react";
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from './initFirebase';

var utl = require('./Utils.js');
const auth = firebase.auth();


// class SaveData extends React.Component {
function SaveData() {
  const [user, loading] = useAuthState(auth);
	// const [lgdInFlag, setLgdInFlag] = useState(false);

  useEffect(() => {
    if (loading) return;
    // if (!user) setLgdInFlag(false);
    // else setLgdInFlag(true);
    /*if (user) {
      //console.log(user);
      console.log(user.uid);
      console.log(user.email);
    }*/
    // if (lgdInFlag) backUpData()
    if (user) backUpData()
    else alert('A user should be logged in to successfully use this route.')
  }, [user, loading]);

  /*constructor(props) {
    super(props);

    this.state = {
      [props.type]: [],
    };

    this.pt = props.type

    if (props.mng === 'T') {
      this.rscCnt = 0
    }
   }*/

  /*componentDidMount() {
    this.backUpData();
  }*/


  const backUpData = () => {
    const copyAllRecordsToDBAsNow = (cpnObj) => {
      var xhr = new XMLHttpRequest();
      const fnName = 'dataBackUp',
      fnUrl = utl.funcServer() + fnName //+ '?owner=' + this.owner
      xhr.open("POST", fnUrl, true);
      xhr.setRequestHeader('Content-Type', 'application/json');

      xhr.onload = function() {
        const rspObj = JSON.parse(this.response);
        cpnObj.rscCnt = rspObj.dataRcdCount
      };
    
      firebase.auth().currentUser.getIdToken(true)
        .then(function (token) {
            xhr.send(JSON.stringify({token: token}));
        })
        .catch(function (err) {
            console.error('dataBackUp error:',err);
        });
    };

    copyAllRecordsToDBAsNow(this);
  }

  // render() {
    return (
      <React.Fragment>
        <div className="container">
          <h2>SaveData</h2>
        </div>
      </React.Fragment>
    );
  // }
}

export default SaveData;
