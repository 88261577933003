import React, {createRef} from "react";
import {Link} from "react-router-dom";
import firebase from './initFirebase';
import './DocInput.css';
import AppDB from './AppDB.js';

var utl = require('./Utils.js');

class SupplyInput extends React.Component {

  constructor(props) {
    super(props);
    this.appDatabase = new AppDB();

    this.state = {
      [props.type]: []
    };

    this.pt = props.type
    this.editFlag = props.editFlag
    this.activBtn = ''
    this.uid = props.uid
    this.title = props.title
    this.url = props.url
    this.author = props.author
    this.lang = props.lang
    this.stop = props.stop
    this.conclusion = props.conclusion
    this.owner = props.ownr

    this.extra = props.extra

    // this.myRef = React.createRef();
    //this.language = React.createRef();
    //this.focusTextInput = this.focusTextInput.bind(this);
    this.inputTitle = createRef(null)
    this.inputURL = createRef(null)
    this.inAuthor = createRef(null)
    this.inLanguage = createRef(null)
    this.inputUID = createRef(null)
    // this.refs ref=

  }


  componentDidMount() {
    console.log("--componentDidMount--");
    this.getUserData();
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.writeUserData();
    }
  }


  writeUserData = () => {
    console.log("writeUserData (SI-Start)");
    this.appDatabase.addCollection(this.state[this.pt],'WorldLife/'+this.pt);
    console.log("writeUserData (SI-End)");
  };


  getUserData = () => {
    console.log("getUserData (Start)");
    //let ref = firebase.database().ref("/"+this.pt);
    let ref = firebase.database().ref("/WorldLife/"+this.pt);
    ref.on("value", snapshot => {
      const syncData = snapshot.val();

      if (syncData===null) {
        console.log("syncData===null !!!!")
        return;
      } else {
        console.log("snapshot.val() !!!!")
      }

      const newDataArray = syncData.reduce(utl.merge, []);
      if (newDataArray!=null) this.setState({[this.pt]:newDataArray});
    });
    console.log("getUserData (End)");
  };


  handleSubmit = event => {
    event.preventDefault();

    if (this.activBtn === 'CNCL') {
      this.stop()
      return
    }

    // let title = this.refs.title.value.trim();
    // let url = this.refs.url.value.trim();
    // let uid = this.refs.uid.value;
    // let author = this.refs.author.value.trim();
    // let lang = this.refs.language.value;
    let uid = this.inputUID.current.value
    let lang = this.inLanguage.current.value
    let title = this.inputTitle.current.value.trim()
    let url = this.inputURL.current.value.trim()
    let author = this.inAuthor.current.value.trim()

    if (utl.isLongYouTubeURL(url)) url = utl.shortYouTubeURL(url)

    if (typeof this.uid !== 'undefined') {uid = this.uid}

    if (uid && title && url && author) { // Updating an existing item.
      // if (typeof this.props.customExec !== undefined) {
      if (typeof this.props.customExec === "function") {
        // We rely on a cloud function to perform the data update.
        this.props.customExec({
          author: author,
          title: title,
          lang: lang,
          url: url,
          uid: uid,
        })
        return;
      }

      // Without a cloud function we can still perform the data update.
      // But the code on the client side has more risk to be hacked.
      // It is therefore safer to use a cloud function.
      const {[this.pt]: clctnBufr = []} = this.state;
      const devIndex = clctnBufr.findIndex(data => {
          return data.uid === uid;
      });

      clctnBufr[devIndex].title = title;
      clctnBufr[devIndex].url = url;
      clctnBufr[devIndex].author = author;
      clctnBufr[devIndex].lang = lang;

      this.setState(this.state);
    } else if (title && url && author) { // Adding a new item.
      // Let us check for a possible duplicate.
      let duplicFlag = false, duplicTitle = '',
          duplTitFlag = false

      this.state[this.pt].map(record => {
        if (duplicFlag) return null
        if (record.url===url) {
          duplicFlag = true
          duplicTitle = record.title
        }
        if (record.title===title) {
          duplTitFlag = true
        }
        return null
      })

      if (duplicFlag) {
        // alert('This document is already in the database!')
        let displayMsg = 'Ce document a déjà été rentré dans la base de données,\n'
        displayMsg += 'avec ce titre: ' + duplicTitle
        alert(displayMsg)
        return
      }

      if (duplTitFlag) {
        // alert('This document is already in the database!')
        const displayMsg = 'Ce titre est déjà utilisé pour un autre document.'
        alert(displayMsg)
        return
      }

      //* Initial DB filling:
      if (false&&!this.state[this.pt].length) {
        utl.supplyInitialLoad(this.pt,this.state[this.pt])
        return;
      }

      let uid = new Date().getTime().toString();
      const {[this.pt]: clctnBufr = []} = this.state;
      let owner = this.owner;
      clctnBufr.push({uid, title, url, author, owner, lang});
      this.setState(this.state);
    }

    this.inputTitle.current.value = ""
    this.inputURL.current.value = ""
    this.inAuthor.current.value = ""
    this.inputUID.current.value = ""
  };


  cnclStr = () => {
    let lng = utl.langPrefer()
    switch (lng) {
      case 'F': 
      return 'Annuler';
      case 'J': 
      return '取消';
      default: 
      return 'Cancel';
    }
  };


  savStr = () => {
    let lng = utl.langPrefer()
    switch (lng) {
      case 'F': 
      return 'Enregistrer';
      case 'J': 
      return '保存';
      default: 
      return 'Save';
    }
  };


  contribMngStr = () => {
    let lng = utl.langPrefer()
    switch (lng) {
      case 'F': 
      return 'Gérer vos contributions';
      case 'J': 
      return '貢献の管理';
      default: 
      return 'Manage your contributions';
    }
  };


  render() {
    return (
        <React.Fragment>
            <div className="inp-block">
            <h1>{
              this.editFlag ? utl.modifyTitle(this.pt) :
                              utl.addTitle(this.pt)
            }</h1>
            <form onSubmit={this.handleSubmit}>
              <div className="form-row">
                <input type="hidden" ref={this.inputUID}//"uid"
                 />
                <div className="form-group col-md-6">
                  <label className='inp-lbl'>{utl.titleWord()}</label>
                  <div className='inp-title'>
                    <input
                      type='text'
                      ref={this.inputTitle}//'title'
                      size='47'
                      defaultValue={this.title}
                      onChange={e => {
                        this.setState((state) => {
                          return {title: e.target.value};
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="form-group col-md-6">
                  <label className='inp-lbl'>{utl.urlWord()}</label>
                  <div className='inp-url'>
                    <input
                      type='text'
                      ref={this.inputURL}//'url'
                      size='47'
                      defaultValue={this.url}
                      onChange={e =>
                        this.setState((state) => {
                          return {url: e.target.value};
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group col-md-6">
                  <label className='inp-lbl'>Auteur</label>
                  <div className='inp-auth'>
                    <input
                      type='text'
                      ref={this.inAuthor}//'author'
                      size='47'
                      defaultValue={this.author}
                      onChange={e =>
                        this.setState((state) => {
                          return {author: e.target.value};
                        })
                      }
                    />
                  </div>
                </div>


                <div className="form-group col-md-6">
                  <label className='inp-lbl'>Language</label>
                  <div className='inp-lang'>
                    <select ref={this.inLanguage}//'language'
                      class='inp-combo'
                      defaultValue={this.lang} >
                    {/* <select ref={this.language}> */}
                      <option value='EN'>English</option>
                      <option value='FR'>French</option>
                    </select>
                  </div>
                </div>


              </div>
              <div className="btn">
                {this.editFlag &&
                  <button type="submit" 
                          className="btn-primary"
                          onClick={() => (this.activBtn = 'CNCL')}>
                    {this.cnclStr()}
                  </button>
                }
                <button type="submit" 
                        className="btn-primary"
                        onClick={() => (this.activBtn = 'SV')}>
                    {this.savStr()}
                </button>
                {this.extra}
              </div>
            </form>
              {!this.editFlag &&
                <div className="lnk">
                  <Link to="/docmng">{this.contribMngStr()}</Link>
                </div>
              }
            </div>
        </React.Fragment>
    )
  }
}

export default SupplyInput;