
function addItem(list,uid,author,title,url,lang) {
  if (uid) uid += Math.floor((Math.random()*10000)+1)
  else uid = new Date().getTime()-300*10000
  list.push({uid,title,url,author,lang})
  return uid
} /* End of addItem */


function setItem(firebase,author,title,url,lang) {
  const rsrcDBRef = firebase.database().ref('WorldLife/General2')
  let newRsrc = rsrcDBRef.push();
  newRsrc.set({author:author,title:title,url:url,lang:lang});
} /* End of setItem */


function docInitialLoad(list) {
  let uid = 0

  uid = addItem(list,uid,"François Asselineau",
                "L'échec du «nouvel ordre mondial»",
                "https://youtu.be/YTt_ejMTOIc","FR")
  uid = addItem(list,uid,"Jean-Luc Mélenchon",
                "Inflation, récession, crise financière internationale ?",
                "https://youtu.be/6Q9Hg9aaHMk","FR")
  uid = addItem(list,uid,"Michel Onfray",
                "Les passeurs et Mélenchon sont les nouveaux négriers !",
                "https://youtu.be/jm7hp-bNKdQ","FR")
  uid = addItem(list,uid,"Idriss Aberkane",
                "Le Scandale Derrière Wikipédia",
                "https://youtu.be/uQh1ZK6OZ8s","FR")
  uid = addItem(list,uid,"François Asselineau",
                "Voeux pour l'année 2023.",
                "https://youtu.be/9bMix69i9FY","FR")
  uid = addItem(list,uid,"Idriss Aberkane",
                "Comment les riches restent riches ?",
                "https://youtu.be/iabQsOjfano","FR")
  uid = addItem(list,uid,"J.J. McCullough",
                "Why I hate Wikipedia (and you should too!)",
                "https://youtu.be/-vmSFO1Zfo8","EN")
  uid = addItem(list,uid,"Luke Smith",
                "I Don't Use or Trust Wikipedia Anymore.",
                "https://youtu.be/c_Q7reJtiLU","EN")
} /* End of docInitialLoad */


// function supplyInitialLoad(type,list) {
//   if (type == 'President') {
//     docInitialLoad(list)
//   } else if (type == 'Legislat') {
//     console.log('Inside supplyInitialLoad '+type);
//     docInitialLoad(list)
//   }
// }


function supplyInitialLoad(type,list) {
//function supplyInitialLoad(firebase) {
  //docInitialLoad(firebase)
  /*if (type === 'President') {
    docInitialLoad(list)
  } else if (type === 'Legislat') {
    console.log('Inside supplyInitialLoad '+type);
    docInitialLoad(list)
  }*/
  docInitialLoad(list)
}


function merge(total, item) {
  total.push(item)
  return total
}


// function bgColor(status) {
//   let referVal = 1
//   if (status == referVal) return '#FF2233';
//   referVal++
//   if (status == referVal) return '#FFBB22';
//   referVal++
//   if (status == referVal) return '#22EE33';
//   referVal++
//   if (status == referVal) return '#2255EE';
//   return '#999999'; // This is not supposed to happen.
// };


function bgColor(status) {
  let referVal = 1
  if (status === referVal) return '#FF2233';
  referVal++
  if (status === referVal) return '#FFBB22';
  referVal++
  if (status === referVal) return '#22EE33';
  referVal++
  if (status === referVal) return '#2255EE';
  return '#999999'; // This is not supposed to happen.
};


// function fgColor(status) {
//   let referVal = 1
//   if (status == referVal) return '#EEFFFF';
//   referVal++
//   if (status == referVal) return '#445566';
//   referVal++
//   if (status == referVal) return '#335522';
//   referVal++
//   if (status == referVal) return '#FFFFEE';
//   return '#777777'; // This is not supposed to happen.
// };


function fgColor(status) {
  let referVal = 1
  if (status === referVal) return '#EEFFFF';
  referVal++
  if (status === referVal) return '#445566';
  referVal++
  if (status === referVal) return '#335522';
  referVal++
  if (status === referVal) return '#FFFFEE';
  return '#777777'; // This is not supposed to happen.
};


function langPrefer() {
  // return sessionStorage.getItem("globalLang")
  return 'F'
};


function titleWord() {
  let lng = langPrefer()

  switch (lng) {
    case 'E': 
      return 'Title';
    case 'F': 
      return 'Titre';
    case 'J': 
      return '題名';
    default: 
      return 'Titre';
  }
}


function urlWord() {
  let lng = langPrefer()

  switch (lng) {
    case 'F': 
      return 'URL';
    case 'J': 
      return 'URL';
    default: 
      return 'URL';
  }
}


const cnclStr = () => {
  let lng = langPrefer()
  switch (lng) {
    case 'F': 
    return 'Annuler';
    case 'J': 
    return '取消';
    default: 
    return 'Cancel';
  }
};


/*const savStr = () => {
  let lng = langPrefer()
  switch (lng) {
    case 'F': 
    return 'Enregistrer';
    case 'J': 
    return '保存';
    default: 
    return 'Save';
  }
};*/


const lgOutStr = () => {
	let lng = langPrefer()
	switch (lng) {
	  case 'F':
		return 'Déconnexion';
	  case 'J':
		return 'ログアウト';
	  default:
		return 'Logout';
	}
};


function addTitle(type) {
  /*let lng = langPrefer()

  if (type === 'Boissons') { // Changed == to ===
    switch (lng) {
      case 'F': 
        return 'Ajouter une nouvelle boisson au menu';
      case 'J': 
        return '献立表に新規飲み物を追加';
      default: 
        return 'Add a new drink to the menu';
    }
  }

  if (type === 'Plats') { // Changed == to ===
    switch (lng) {
      case 'F': 
        return 'Ajouter un nouveau plat au menu';
      case 'J': 
        return '献立表に新規食べ物を追加';
      default: 
        return 'Add a new dish to the menu';
    }
  }*/

  return 'Ajoutez un nouveau document à la liste';
  // return 'Unknown type: ' + type; // This should not happen.
}


function modifyTitle(type) {
  let lng = langPrefer()

if (type === 'General') {
  switch (lng) {
    case 'F': 
      return 'Modifier ce document';
    case 'J': 
      return 'このメディアを変更';
    default: 
      return 'Modify this document';
  }
}

return 'Unknown type: ' + type; // This should not happen.
}


function updateTitle(type) {
  let lng = langPrefer()

if (type === 'Boissons') { // Changed == to ===
  switch (lng) {
    case 'F': 
      return 'Modifier le menu des boissons';
    case 'J': 
      return '飲み物の献立表を変更';
    default: 
      return 'Modify the drink menu';
  }
}

if (type === 'Plats') { // Changed == to ===
  switch (lng) {
    case 'F': 
      return 'Modifier le menu des plats';
    case 'J': 
      return '食べ物の献立表を変更';
    default: 
      return 'Modify the food menu';
  }
}

return 'Unknown type: ' + type; // This should not happen.
}


function menuTitle(type) {
  let lng = langPrefer()

  if (type === 'Boissons') { // Changed == to ===
    switch (lng) {
      case 'F': 
        return 'Carte des boissons';
      case 'J': 
        return '飲み物の献立表';
      default: 
        return 'Drink menu';
    }
  }

  if (type === 'Plats') { // Changed == to ===
    switch (lng) {
      case 'F': 
        return 'Carte des plats';
      case 'J': 
        return '食べ物の献立表';
      default: 
        return 'Food menu';
    }
  }

  return 'Unknown type: ' + type; // This should not happen.
}


function updatePageTitle(type) {
  let lng = langPrefer()

  if (type === 'Boissons') { // Changed == to ===
    switch (lng) {
      case 'F': 
        return 'Mise à jour de la carte des boissons';
      case 'J': 
        return '飲み物の献立表の変更と編集';
      default: 
        return 'Drink menu update page';
    }
  }

  if (type === 'Plats') { // Changed == to ===
    switch (lng) {
      case 'F': 
        return 'Mise à jour de la carte des plats';
      case 'J': 
        return '食べ物の献立表の変更と編集';
      default: 
        return 'Food menu update page';
    }
  }

  return 'Unknown type: ' + type; // This should not happen.
}


function custOrdLstTitle() {
  let lng = langPrefer()

  switch (lng) {
    case 'F': 
      return 'Liste des commandes';
    case 'J': 
      return '注文の一覧';
    default: 
      return 'Customer Order List';
  }
}


function contribMngTitle(cnt) {
  let lng = langPrefer(), rtnStr = ''

  switch (lng) {
    case 'F': 
      rtnStr = 'Liste des ' + cnt
      rtnStr += ' documents que vous avez mis en ligne.'
      rtnStr += " Vous pouvez utiliser les boutons sur le côté de chaque élément pour respectivement le supprimer de la liste ou bien pour modifier l'information correspondante."
      break
    case 'J': 
      rtnStr = 'Liste des ' + cnt
      rtnStr += ' documents que vous avez mis en ligne.'
      rtnStr += " Vous pouvez utiliser les boutons sur le côté de chaque élément pour respectivement le supprimer de la liste ou bien pour modifier l'information correspondante."
      break
    default:
      rtnStr = 'List of the ' + cnt
      rtnStr += ' resources that you have uploaded.'
      rtnStr += ' You can use the buttons on the side of each item to respectively remove it from the list or to change its related information.'
      break
  }

  return rtnStr
}


function isLongYouTubeURL(url) {
  const youTubeMark = 'https://www.youtube.com/watch?v='

  if (url.length === 43) { // Classic URL.
    if (url.substring(0,32) === youTubeMark) return true
  }

  if ((url.length>49) && (url.substring(43,49)==='&list=')) { // List URL.
    if (url.substring(0,32) === youTubeMark) return true 
  }

  return false
} /* End of isLongYouTubeURL */


function shortYouTubeURL(url) {
  const youTubeKey = url.substring(32,43)
  return 'https://youtu.be/'+youTubeKey
} /* End of shortYouTubeURL */


function funcServer() {
  return 'https://us-central1-worldhappen-206b8.cloudfunctions.net/'
} /* End of funcServer */


module.exports = {docInitialLoad,
                  supplyInitialLoad,merge,fgColor,bgColor,
                  langPrefer,titleWord,urlWord,
                  cnclStr,lgOutStr,
                  addTitle,modifyTitle,
                  updateTitle,menuTitle,
                  updatePageTitle,custOrdLstTitle,
                  contribMngTitle,
                  isLongYouTubeURL,shortYouTubeURL,
                  funcServer};
