import React from "react";
import firebase from './initFirebase';
import './DocList.css';
import DocInput from "./DocInput";
import trashClp from './Images/trash.png';
import editClp from './Images/edit.png';

var utl = require('./Utils.js');


function IntroEnglish(props) {
  return (
    <React.Fragment>
      <div className="dilmHd">
        The world in 2024
      </div>
      <div className={props.styl}>
        The events shaking our world and our civilization lead us to wonder what is about to happen in the near future. This little web site aims at building a collection of resources available to anyone who is interested, to think and meditate on this big subject.<br/>
        The goal is not to tell people what to do or what to think. It is only to bring some elements to feed a thinking process that a lot of us have already engaged in.<br/>
        The collection currently contains <span>{props.nb}</span> documents seemingly relevant for the matter. One can note that they do not necessarily match any political tendency. They are here simply because they have been considered as holding some value for our purpose. Just click on the one you wish to see in order to access it.
      </div>
    </React.Fragment>
  )
} /* End of IntroEnglish */


function IntroFrench(props) {
  return (
    <React.Fragment>
      <div className="dilmHd">
        Le monde en 2024
      </div>
      <div className={props.styl}>
        Les évènements qui secouent notre monde et notre civilisation nous poussent à nous interroger sur ce qui nous attend dans un avenir proche. Ce petit site se propose de constituer une collection de documents permettant à qui le souhaitera de consulter ce qui lui convient afin d'approfondir sa réflexion personnelle sur le sujet.<br/>
        L'objectif n'est pas de dire ce qu'il faut faire ou ce qu'il faut penser; il est seulement d'apporter des éléments à un processus d'interrogation et de méditation qui anime sans aucun doute déjà beaucoup d'entre nous.<br/>
        La série contient actuellement <span>{props.nb}</span> documents qui semblent intéressant pour le but recherché. Il faut noter que ceux-ci ne vont pas nécessairement tous politiquement dans le même sens. Ils sont simplement là car ayant été considérés comme susceptibles d'apporter des éléments de valeur. Il suffit de cliquer sur celui que vous voulez visualiser pour y accéder.
      </div>
    </React.Fragment>
  )
} /* End of IntroFrench */


function IntroArchEnglish(props) {
  return (
    <React.Fragment>
      <div className="dilmHd">
        Archive for the year {props.year}.
      </div>
      <div className={props.styl}>
        During the year {props.year}, a total of {props.nb} documents have been chosen as worth seeing. This selection was made from the documents we've had the opportunity and time to explore. It does not imply another selection could not have been made. You're more than welcome to investigate on your own and dig for more and deeper information on any subject relevant to you. Below is the list; click on your desired item to view it.
      </div>
    </React.Fragment>
  )
} /* End of IntroArchEnglish */


function IntroArchFrench(props) {
  return (
    <React.Fragment>
      <div className="dilmHd">
        Archives de l'année {props.year}.
      </div>
      <div className={props.styl}>
        Pendant l'année {props.year}, un total de {props.nb} documents a été considéré comme valant la peine d'être vu. Bien sûr, cela concerne les documents que nous avons eu l'opportunité de découvrir et le temps de visionner. Cela ne signifie pas qu'une autre sélection n'aurait pas été possible. Vous êtes encouragés à approfondir les sujets qui vous intéressent en menant vos propres recherches. Ci-dessous se trouve la liste; cliquez sur celui que vous souhaitez visualiser.
      </div>
    </React.Fragment>
  )
} /* End of IntroArchFrench */


function Introduction(props) {
  switch (props.usrPrfLng) {
    case 'E':
      return IntroEnglish(props)
    case 'F':
      return IntroFrench(props)
    default:
      return "UNKNOWN LANGUAGE:"+props.usrPrfLng+'!'
  }
} /* End of Introduction */


function IntroArch(props) {
  switch (props.usrPrfLng) {
    case 'E':
      return IntroArchEnglish(props)
    case 'F':
      return IntroArchFrench(props)
    default:
      return "UNKNOWN LANGUAGE:"+props.usrPrfLng+'!'
  }
} /* End of IntroArch */


function show(lng,usrPrfLng) {
  let lngKwd
  switch(usrPrfLng) {
    case 'E':
      lngKwd = 'Language:'
      if (lng==='EN') return lngKwd+'English'
      if (lng==='FR') return lngKwd+'French'
      break;
    case 'F':
      lngKwd = 'Langue: '
      if (lng==='EN') return lngKwd+'anglais'
      if (lng==='FR') return lngKwd+'français'
      break;
    default:
      return "UNKNOWN LANGUAGE:"+usrPrfLng+'!'
  }
}


class DocList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      [props.type]: [],
      showEditBox: false,
      usrPrefLng: props.upl
    };

    this.pt = props.type
    this.year = props.year

    if (props.mng === 'T') {
      this.mngFlag = true
      this.owner = props.ownr
      this.uid = ''
      this.ediTitle = ''
      this.url = ''
      this.author = ''
      this.lang = ''
    }
  }

  componentDidMount() {
    this.getUserData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.writeUserData();
    }
  }

  dbPath = () => {
    let path = "/WorldLife/"+this.pt
    if (this.year!=null) path += '/'+this.year
    return path
  }

  writeUserData = () => {
    firebase.database()
      .ref(this.dbPath())
      .set(this.state[this.pt]);
  
      if (false&&!this.state[this.pt].length) {
        // This seems a good place to upload data when the DB is empty.
        utl.supplyInitialLoad(this.pt,this.state[this.pt])
      }
  }

  getUserData = () => {
    let ref = firebase.database().ref(this.dbPath());
    ref.on("value", snapshot => {
      const syncData = snapshot.val();
      const newDataArray = syncData.reduce(utl.merge,[]);
      if (newDataArray!=null) this.setState({[this.pt]:newDataArray});
    });
  };


  removeData = item => {
    const {[this.pt]: dataSync = []} = this.state;
    const newState = dataSync.filter(data => {
      return data.uid !== item.uid;
    });

    const newDataArray = newState.reduce(utl.merge,[]);
    if (newDataArray!=null) this.setState({[this.pt]:newDataArray});
  };


  bringEditBox = item => {
    this.uid = item.uid
    this.ediTitle = item.title
    this.url = item.url
    this.author = item.author
    this.lang = item.lang
    this.setState({
      showEditBox: true
    });
  };


  cancelUpdate = () => {
    this.setState({showEditBox: false});
  }


  render() {
    const {[this.pt]: clctnBufr = []} = this.state;
    let dmIntrStyl, dcBnStyl, dcAthStyl
    const trashImg = trashClp, editImg = editClp

    if (window.matchMedia("(max-width: 375px)").matches) {
      dmIntrStyl = 'dilmIntro dmIntrFnSz2'
      dcBnStyl = 'docBtn dcBnFnSz2'
      dcAthStyl = 'docAuth dcAtFnSz2'
    } else if (window.matchMedia("(max-width: 768px)").matches) {
      dmIntrStyl = 'dilmIntro dmIntrFnSz1'
      dcBnStyl = 'docBtn dcBnFnSz1'
      dcAthStyl = 'docAuth dcAtFnSz1'
    } else {
      dcBnStyl = 'docBtn dcBnFnSz0'
      dcAthStyl = 'docAuth dcAtFnSz0'
      dmIntrStyl = 'dilmIntro dmIntrFnSz0'
    }

    /*
      For a more responsive behavior we may want to use use-media or react-responsive.

      See these links:
      https://rpf-noblog.com/2021-01-17/react-hook-usemedia/
      https://www.netlify.com/blog/2020/12/05/building-a-custom-react-media-query-hook-for-more-responsive-apps/
      https://bestofreactjs.com/repo/contra-react-responsive-react-css-style
    */

    return (
      <React.Fragment>
        <div className="container">
          {
            (this.pt==='General') && !this.mngFlag &&
            <Introduction styl={dmIntrStyl} 
              nb={clctnBufr.length}
              usrPrfLng={this.state.usrPrefLng} />
          }
          {
            (this.pt==='Archive') &&
            <IntroArch styl={dmIntrStyl}
              year={this.year}
              nb={clctnBufr.length}
              usrPrfLng={this.state.usrPrefLng} />
          }
          {
            this.mngFlag && !this.state.showEditBox &&
                <React.Fragment>
                  <div className="mngLstHd">
                    List of resources that you have uploaded:
                  </div>
                </React.Fragment>
          }
          {!this.state.showEditBox &&
            <div className='docsLst'>
              {clctnBufr.map((item,index) => (
                <div key={item.uid}>
                  <div className="docCard">
                    <button type="submit" 
                            onClick={() => window.open(item.url, "_blank")}>
                      <label className={dcBnStyl}>{item.title}</label>
                    </button>
                    <div className="authrBtnBlk">
                      <label className='langLabl'>{show(item.lang,this.state.usrPrefLng)}</label>
                      <label className={dcAthStyl}>{item.author}</label>
                      {this.mngFlag &&
                      <button
                        onClick={() => this.removeData(item)}
                        className="trshBtn">
                        <img src={trashImg} alt='TRASH' width='25' height='30'/>
                      </button>}
                      {this.mngFlag &&
                      <button
                        onClick={() => this.bringEditBox(item)}
                        className="trshBtn">
                        <img src={editImg} alt='EDIT' width='25' height='30'/>
                      </button>}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          }
          {this.state.showEditBox &&
            <DocInput type='General'
              editFlag={true}
              ownr={this.owner}
              uid={this.uid}
              title={this.ediTitle}
              url={this.url}
              author={this.author}
              lang={this.lang}
              stop={() => this.cancelUpdate()} />
          }
        </div>
      </React.Fragment>
    );
  }
}

export default DocList;
