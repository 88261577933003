import React from "react";
import firebase from './initFirebase';
import './DocList.css';
import DocInput from "./DocInput";
import trashClp from './Images/trash.png';
import editClp from './Images/edit.png';
// import editClp from './Images/edit.svg';

var utl = require('./Utils.js');


function IntroEnglish(props) {
  return (
    <React.Fragment>
      <div className="dilmHd">
        The world in 2023
      </div>
      <div className={props.styl}>
        The events shaking our world and our civilization lead us to wonder what is about to happen in the near future. This little web site aims at building a collection of resources available to anyone who is interested, to think and meditate on this big subject.<br/>
        The goal is not to tell people what to do or what to think. It is only to bring some elements to feed a thinking process that a lot of us have already engaged in.<br/>
        The collection currently contains <span>{props.nb}</span> documents seemingly relevant for the matter. One can note that they do not necessarily match any political tendency. They are here simply because they have been considered as holding some value for our purpose. Just click on the one you wish to see in order to access it.
      </div>
    </React.Fragment>
  )
} /* End of IntroEnglish */


function IntroFrench(props) {
  return (
    <React.Fragment>
      <div className="dilmHd">
        Le monde en 2023
      </div>
      <div className={props.styl}>
        Les évènements qui secouent notre monde et notre civilisation nous poussent à nous interroger sur ce qui nous attend dans un avenir proche. Ce petit site se propose de constituer une collection de documents permettant à qui le souhaitera de consulter ce qui lui convient afin d'approfondir sa réflexion personnelle sur le sujet.<br/>
        L'objectif n'est pas de dire ce qu'il faut faire ou ce qu'il faut penser; il est seulement d'apporter des éléments à un processus d'interrogation et de méditation qui anime sans aucun doute déjà beaucoup d'entre nous.<br/>
        La série contient actuellement <span>{props.nb}</span> documents qui semblent intéressant pour le but recherché. Il faut noter que ceux-ci ne vont pas nécessairement tous politiquement dans le même sens. Ils sont simplement là car ayant été considérés comme susceptibles d'apporter des éléments de valeur. Il suffit de cliquer sur celui que vous voulez visualiser pour y accéder.
      </div>
    </React.Fragment>
  )
} /* End of IntroFrench */


function Introduction(props) {
  switch (props.usrPrfLng) {
    case 'E':
      return IntroEnglish(props)
    case 'F':
      return IntroFrench(props)
    default:
      return "UNKNOWN LANGUAGE:"+props.usrPrfLng+'!'
  }
} /* End of Introduction */


function show(lng,usrPrfLng) {
  let lngKwd
  switch(usrPrfLng) {
    case 'E':
      lngKwd = 'Language:'
      if (lng==='EN') return lngKwd+'English'
      if (lng==='FR') return lngKwd+'French'
      break;
    case 'F':
      lngKwd = 'Langue: '
      if (lng==='EN') return lngKwd+'anglais'
      if (lng==='FR') return lngKwd+'français'
      break;
    default:
      return "UNKNOWN LANGUAGE:"+usrPrfLng+'!'
  }
}


class DocOwnList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      [props.type]: [],
      showEditBox: false,
      usrPrefLng: props.upl
    };

    this.pt = props.type
    // this.usrPrefLng = props.upl
    // this.setState({usrPrefLng: props.upl});

    if (props.mng === 'T') {
      this.mngFlag = true
      this.owner = props.ownr
      this.uid = ''
      this.ediTitle = ''
      this.url = ''
      this.author = ''
      this.lang = ''
      this.rscCnt = 0
    } //else this.setState({showEditBox: false});
   }

  componentDidMount() {
    this.getUserData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      // this.writeUserData();
    }
  }

  writeUserData = () => {
    firebase.database()
      .ref("/WorldLife/"+this.pt)
      .set(this.state[this.pt]);
  
      if (!this.state[this.pt].length) {
        // This seems a good place to upload data when the DB is empty.
        utl.supplyInitialLoad(this.pt,this.state[this.pt])
      }
  }


  getUserData = () => {
    const getOwnedRecordsFromDB = (cpnObj) => {
      var xhr = new XMLHttpRequest();
      const fnName = 'findRecordsOwnedBy',
      fnUrl = utl.funcServer() + fnName + '?owner=' + this.owner
      xhr.open("POST", fnUrl, true);
      xhr.setRequestHeader('Content-Type', 'application/json');

      xhr.onload = function() {
        const rspObj = JSON.parse(this.response);
        cpnObj.rscCnt = rspObj.ownerCount

        cpnObj.state[cpnObj.pt] = rspObj.resultArray.reduce(utl.merge,[]);
        cpnObj.setState(cpnObj.state[cpnObj.pt]);
      };
    
      firebase.auth().currentUser.getIdToken(true)
        .then(function (token) {
            xhr.send(JSON.stringify({token: token}));
        })
        .catch(function (err) {
            console.error('findRecordsOwnedBy error:',err);
        });
    };

    getOwnedRecordsFromDB(this);
  }


  updateUserData = (updObj) => {
    var xhr = new XMLHttpRequest();
    const fnName = 'dataRecordUpdate',
    fnUrl = utl.funcServer() + fnName
    xhr.open("POST", fnUrl);

    xhr.setRequestHeader('Content-Type', 'application/json');

    xhr.onload = function() {
      const rspObj = JSON.parse(this.response);

      if (rspObj.Status === "Data-Not-Found") {
        const usrMsg = 'No data belonging to you was found with the provided UID.'
        alert(usrMsg)
        return
      }

      if (rspObj.Status === "Data-Updated") {
        const usrMsg = 'One record was updated in the database.'
        alert(usrMsg)
      }
    };

    xhr.onabort = function () {
      console.log('The dataRecordUpdate request was aborted.');
    };

    xhr.onerror = function () {
      console.log("An error occurred during the dataRecordUpdate transaction.");
    };

    firebase.auth().currentUser.getIdToken(true)
      .then(function (token) {
          xhr.send(JSON.stringify({
            //from: email,
            token: token,
            author: updObj.author,
            title: updObj.title,
            url: updObj.url,
            lang: updObj.lang,
            id: updObj.uid,
          }));
      })
      .catch(function (err) {
          console.error(err);
      });
  };


  removeData = item => {
    const removeRecordFromDB = (cpnObj,uid) => {
      var xhr = new XMLHttpRequest();
      const fnName = 'dataRecordRemove',
      fnUrl = utl.funcServer() + fnName
      xhr.open("POST", fnUrl, true);
      xhr.setRequestHeader('Content-Type', 'application/json');

      xhr.onload = function() {
        const rspObj = JSON.parse(this.response);

        if (rspObj.Status === "Data-Not-Found") {
          const usrMsg = 'No data belonging to you was found with the provided UID.'
          alert(usrMsg)
          return
        }

        const {[cpnObj.pt]: dataSync = []} = cpnObj.state;
        const newState = dataSync.filter(data => {
          return data.uid !== uid;
        });

        cpnObj.state[cpnObj.pt] = newState.reduce(utl.merge,[]);
        cpnObj.setState(cpnObj.state[cpnObj.pt]);
        cpnObj.rscCnt = newState.length

        if (rspObj.Status === "Data-Removed") {
          const usrMsg = 'One record was removed from the database.'
          alert(usrMsg)
        }
      };
    
      firebase.auth().currentUser.getIdToken(true)
        .then(function (token) {
            xhr.send(JSON.stringify({
              token: token,
              id: uid,
            }));
        })
        .catch(function (err) {
            console.error('dataRecordRemove error:',err);
        });
    };

    removeRecordFromDB(this,item.uid);
    // removeRecordFromDB(this,'1681542043528');
  };


  bringEditBox = item => {
    this.uid = item.uid
    this.ediTitle = item.title
    this.url = item.url
    this.author = item.author
    this.lang = item.lang

    this.setState({
      showEditBox: true
    });
  };


  cancelUpdate = () => {
    this.setState({showEditBox: false});
  }


  render() {
    const {[this.pt]: clctnBufr = []} = this.state;
    let dmIntrStyl, dcBnStyl, dcAthStyl
    const trashImg = trashClp, editImg = editClp

    if (window.matchMedia("(max-width: 375px)").matches) {
      dmIntrStyl = 'dilmIntro dmIntrFnSz2'
      dcBnStyl = 'docBtn dcBnFnSz2'
      dcAthStyl = 'docAuth dcAtFnSz2'
      // console.log('matchMedia: max-width=375px YES')
    } else if (window.matchMedia("(max-width: 768px)").matches) {
      dmIntrStyl = 'dilmIntro dmIntrFnSz1'
      dcBnStyl = 'docBtn dcBnFnSz1'
      dcAthStyl = 'docAuth dcAtFnSz1'
    } else {
      dcBnStyl = 'docBtn dcBnFnSz0'
      dcAthStyl = 'docAuth dcAtFnSz0'
      dmIntrStyl = 'dilmIntro dmIntrFnSz0'
      // console.log('matchMedia: max-width=375px NO')
    }

    return (
      <React.Fragment>
        <div className="container">
          {
            (this.pt==='General') && !this.mngFlag &&
            <Introduction styl={dmIntrStyl} 
              nb={clctnBufr.length}
              usrPrfLng={this.state.usrPrefLng} />
          }
          {
            this.mngFlag && !this.state.showEditBox &&
                <React.Fragment>
                  <div className="mngLstHd">
                    {utl.contribMngTitle(this.rscCnt)}
                  </div>
                </React.Fragment>
          }
          {!this.state.showEditBox &&
            <div className='docsLst'>
              {clctnBufr.map((item,index) => (
                <div key={item.uid} className="cardBlock">
                  <div className="docCard">
                    <button type="submit" 
                            onClick={() => window.open(item.url, "_blank")}>
                      <label className={dcBnStyl}>{item.title}</label>
                    </button>
                    <div className="authrBtnBlk">
                      <label className='langLabl'>{show(item.lang,this.state.usrPrefLng)}</label>
                      <label className={dcAthStyl}>{item.author}</label>
                    </div> {/* authrBtnBlk */}
                  </div> {/* docCard */}
                      {/* {this.mngFlag &&
                      <button
                        onClick={() => this.removeData(item)}
                        className="trshBtn">
                        <img src={trashImg} width='25' height='30'/>
                      </button>}
                      {this.mngFlag &&
                      <button
                        onClick={() => this.bringEditBox(item)}
                        className="trshBtn">
                        <img src={editImg} width='25' height='30'/>
                      </button>} */}

                      {this.mngFlag &&
                      <div className='rmedBtns'>
                        <button
                          onClick={() => this.removeData(item)}
                          className="trshBtn">
                          <img src={trashImg} alt='TRASH' width='25' height='30'/>
                        </button>
                        <button
                          onClick={() => this.bringEditBox(item)}
                          className="trshBtn">
                          <img src={editImg} alt='EDIT' width='25' height='30'/>
                        </button>
                      </div>}



                    {/* </div> authrBtnBlk */}
                  {/* </div> docCard */}
                </div>
              ))}
            </div>
          }
          {this.state.showEditBox &&
            <DocInput type='General'
              editFlag={true}
              ownr={this.owner}
              uid={this.uid}
              title={this.ediTitle}
              url={this.url}
              author={this.author}
              lang={this.lang}
              // customExec={() => this.updateUserData()}
              customExec={this.updateUserData}
              stop={() => this.cancelUpdate()} />
          }


        </div>
      </React.Fragment>
    );
  }
}

export default DocOwnList;
