import firebase from 'firebase/compat/app';

var utl = require('./Utils.js');

class AppDB {
  addCollection(data,collection) {
    return firebase.database().ref('/'+collection).set(data);
  }


  setAdminUser(email,flag) {
    console.log("setAdminUser (fired)");
    var xhr = new XMLHttpRequest();
    const fnName = 'setAdminRights',
    fnUrl = utl.funcServer() + fnName
    xhr.open("POST", fnUrl, true);

    xhr.setRequestHeader('Content-Type', 'application/json');

    xhr.onload = function() {
      alert(this.responseText);
    };

    xhr.onabort = function () {
      console.log('** The request was aborted');
    };

    xhr.onerror = function () {
      console.log("** An error occurred during the    transaction");
    };

    firebase.auth().currentUser.getIdToken(true)
      .then(function (token) {
          xhr.send(JSON.stringify({
            from: email,
            token: token,
            flag: flag
          }));
      })
      .catch(function (err) {
          console.error(err);
      });
  }


  setLangPref(lng) {
    // This needs needs to be set following the model of setAdminUser.
    // .......
    console.log("setLangPref (fired)");
    var xhr = new XMLHttpRequest();
    const fnName = 'setPreferLang',
    fnUrl = utl.funcServer() + fnName
    xhr.open("POST", fnUrl, true);

    xhr.setRequestHeader('Content-Type', 'application/json');

    xhr.onload = function() {
      alert(this.responseText);
    };

    xhr.onabort = function () {
      console.log('** The request was aborted');
    };

    xhr.onerror = function () {
      console.log("** An error occurred during the    transaction");
    };

    firebase.auth().currentUser.getIdToken(true)
      .then(function (token) {
          xhr.send(JSON.stringify({
            lang: lng,
            token: token
          }));
      })
      .catch(function (err) {
          console.error(err);
      });
  }
}

export default AppDB;
