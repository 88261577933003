import React, { useEffect, useState } from "react";
import firebase from './initFirebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import Login from "./Login";
import DocInput from "./DocInput";
import './InputEntry.css';
// import AppDB from './AppDB.js';

var utl = require('./Utils.js');
const auth = firebase.auth();

// const logout = () => {
//   auth.signOut();
//   setLgdInFlag(false);
// };


function InputEntry() {
	const [user, loading] = useAuthState(auth);
	const [lgdInFlag, setLgdInFlag] = useState(false);

  useEffect(() => {
    if (loading) return;
    if (!user) setLgdInFlag(false);
    else setLgdInFlag(true);
    /*if (user) {
      //console.log(user);
      console.log(user.uid);
      console.log(user.email);
    }*/
  }, [user, loading]);

  const logout = () => {
    auth.signOut();
    setLgdInFlag(false);
  };

  const lgtBtn = <button type="submit"
                   className="dashboard__btn"
                   onClick= {logout}>
                   {utl.lgOutStr()}
                 </button>

  return (
    <React.Fragment>


      {lgdInFlag &&
       <div className='InpBlock'>
         <DocInput type='General' ownr={user.uid} extra={lgtBtn}/>
			   {/* <button type="submit"
                 className="dashboard__btn"
                 onClick= {logout}>
			   	{utl.lgOutStr()}
			   </button> */}
       </div>}
      {!lgdInFlag && <Login/>}
    </React.Fragment>
  )
}

export default InputEntry;