import React, {useState} from "react";
// import { useAuthState } from "react-firebase-hooks/auth";
// import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
// import { /*auth,*/ sendPasswordResetEmail } from "./firebase";
import firebase from './initFirebase';
import "./Reset.css";

const auth = firebase.auth();

const sendPasswordResetEmail = async (email) => {
  try {
    await auth.sendPasswordResetEmail(email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

function Reset() {
  const [email, setEmail] = useState("");
  // const [user, loading, error] = useAuthState(auth);
  /*const history = useNavigate();
  useEffect(() => {
    if (loading) return;
    if (user) history.replace("/dashboard");
  }, [user, loading]);*/
  return (
    <div className="reset">
      <div className="reset__container">
        <input
          type="text"
          className="reset__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <button
          className="reset__btn"
          onClick={() => sendPasswordResetEmail(email)}
        >
          Send password reset email
        </button>
        {false &&
        <div>
          Don't have an account? <Link to="/register">Register</Link> now.
        </div>}
      </div>
    </div>
  );
}
export default Reset;
