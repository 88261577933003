
import React, { useEffect, useState } from "react";
import firebase from './initFirebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import Login from "./Login";
import DocOwnList from './DocOwnList';
// import DocInput from "./DocInput";
import './InputManage.css';
// import AppDB from './AppDB.js';

var utl = require('./Utils.js');
const auth = firebase.auth();


function InputManage() {
	const [user, loading] = useAuthState(auth);
	const [lgdInFlag, setLgdInFlag] = useState(false);

  useEffect(() => {
    if (loading) return;
    if (!user) setLgdInFlag(false);
    else setLgdInFlag(true);
    /*if (user) {
      //console.log(user);
      console.log(user.uid);
      console.log(user.email);
    }*/
  }, [user, loading]);

  const logout = () => {
    auth.signOut();
    setLgdInFlag(false);
  };

  return (
    <React.Fragment>
      {lgdInFlag &&
       <div className='InpBlock'>
         {/* <DocOwnList type='General' mng='T' ownr={user.uid} upl='F'/> */}
         <DocOwnList type='Own' mng='T' ownr={user.uid} upl='F'/>
			   <button type="submit"
                 className="dashboard__btn"
                 onClick= {logout}>
			   	{utl.lgOutStr()}
			   </button>
       </div>}
      {/* {!lgdInFlag && <Login origin='mng2'/>} */}
      {!lgdInFlag && <Login origin='mng'/>}
      {/* {lgdInFlag &&
			<button className="dashboard__btn" onClick={logout}>
				{utl.lgOutStr()}
			</button>
      } */}

    </React.Fragment>
  )
}


export default InputManage;
