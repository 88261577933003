import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
// import {App} from './App';
import App from './App';
import InputEntry from './InputEntry';
import InputManage from './InputManage';
import Login from "./Login"; //-Set later-
import Reset from "./Reset";
import AlternateSites from "./AlternateSites";
import NewsChannels from "./NewsChannels";
import QR from "./QR";
import SaveData from "./SaveData";
import SetStore from "./SetStore";
// import './DocList.css'; //-Set later-


class RouterBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logdInSatus: false
    };
  }


  updateStatus = (logFlag) => {
    if (logFlag) console.log('LOGGED-IN')
    else  console.log('LOGGED-OUT')
    this.setState({
      logdInSatus: logFlag
    })
  }
  
  
  render() {
    return (
      <Router>
        <Routes>
          <Route exact path="/" element={<App/>} />
          <Route exact path="/docmng" element={<InputManage/>} />
          <Route exact path="/docinp" element={<InputEntry/>} />
          <Route exact path="/login" element={<Login/>} />
          <Route exact path="/reset" element={<Reset/>} />
          <Route exact path="/alternWS" element={<AlternateSites upl='F'/>} />
          <Route exact path="/alternChnl" element={<NewsChannels upl='E'/>} />
          <Route exact path="/siteQR" element={<QR/>} />
          <Route exact path="/save-data" element={<SaveData/>} />
          <Route exact path="/set-store" element={<SetStore/>} />
          {/* <Route exact path="/test" element={<Test/>} /> */}
        </Routes>
      </Router>
    );
  }
}


export default RouterBlock;