import React, {useState} from "react";
import {Tab,Tabs,TabList,TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import './App.css';
import DocList from './DocList';
import AlternateSites from "./AlternateSites";
import NewsChannels from "./NewsChannels";
import SharePage from "./SharePage";
import FrLngClp from './Images/France.png';
import EnLngClp from './Images/US.png';


function App() {
	const [prefLang, setPrefLang] = useState('F');

  const handleFlagClick = () => {
    switch (prefLang) {
      case 'E':
        setPrefLang('F');
        break;
      case 'F':
        setPrefLang('E');
        break;
      default:
        setPrefLang('E');
        break;
    }
  };

  const langSwapXplain = () => {
    let resultStr
    switch (prefLang) {
      case 'E':
        resultStr = "Cliquer sur le drapeau pour changer la langue."
        break;
      case 'F':
        resultStr = "Click on the flag to change the language."
        break;
      default:
        resultStr = "UNKNOWN LANGUAGE."
        break;
    }
    return resultStr
  };

  const tabTitle = (tabName) => {
    let resultStr
    switch (tabName) {
      case 'SD':
        switch (prefLang) {
          case 'E':
            resultStr = "Selected Documents"
            break;
          case 'F':
            resultStr = "Documents Sélectionnés"
            break;
          default:
            resultStr = "UNKNOWN LANGUAGE."
            break;
        }
        break;
      case 'AS':
        switch (prefLang) {
          case 'E':
            resultStr = "Alternative News Websites"
            break;
          case 'F':
            resultStr = "Sites d'Information Alternatifs"
            break;
          default:
            resultStr = "UNKNOWN LANGUAGE."
            break;
        }
        break;
      case 'AC':
        switch (prefLang) {
          case 'E':
            resultStr = "Alternative News Channels"
            break;
          case 'F':
            resultStr = "Chaînes d'Information Alternatives"
            break;
          default:
            resultStr = "UNKNOWN LANGUAGE."
            break;
        }
        break;
      case 'AR':
        switch (prefLang) {
          case 'E':
            resultStr = "Archive 2023"
            break;
          case 'F':
            resultStr = "Archives 2023"
            break;
          default:
            resultStr = "UNKNOWN LANGUAGE."
            break;
        }
        break;
      case 'SH':
        switch (prefLang) {
          case 'E':
            resultStr = "Share"
            break;
          case 'F':
            resultStr = "Partager"
            break;
          default:
            resultStr = "UNKNOWN LANGUAGE."
            break;
        }
        break;
      default:
        break;
    }
    return resultStr
  };

  return (
    <React.Fragment>
      <div className={'pageHead'}>
        <div id={'langSwap'}>
          <button
            onClick={handleFlagClick}>
            {(prefLang==='E') &&
            <img src={FrLngClp} width='64' height='43' alt='' />}
            {(prefLang==='F') &&
            <img src={EnLngClp} width='64' height='34' alt='' />}
          </button>
          <label id={'lngSwpXpln'}>
            {langSwapXplain()}
           </label>
        </div>
      </div>

      <Tabs>
        <TabList>
          <Tab>{tabTitle('SD')}</Tab>
          <Tab>{tabTitle('AS')}</Tab>
          <Tab>{tabTitle('AC')}</Tab>
          <Tab>{tabTitle('AR')}</Tab>
          <Tab>{tabTitle('SH')}</Tab>
        </TabList>
        <TabPanel>
          {(prefLang==='E') && <DocList type='General' upl='E'/>}
          {(prefLang==='F') && <DocList type='General' upl='F'/>}
        </TabPanel>
        <TabPanel>
          {(prefLang==='E') && <AlternateSites upl='E'/>}
          {(prefLang==='F') && <AlternateSites upl='F'/>}
        </TabPanel>
        <TabPanel>
          {(prefLang==='E') && <NewsChannels upl='E'/>}
          {(prefLang==='F') && <NewsChannels upl='F'/>}
        </TabPanel>
        <TabPanel>
          {(prefLang==='E') && <DocList type='Archive'
                                        year='2023' upl='E'/>}
          {(prefLang==='F') && <DocList type='Archive'
                                        year='2023' upl='F'/>}
        </TabPanel>
        <TabPanel>
          {(prefLang==='E') && <SharePage upl='E'/>}
          {(prefLang==='F') && <SharePage upl='F'/>}
        </TabPanel>
      </Tabs>
    </React.Fragment>
  );
}


export default App;
// export {App};
